/* .react-calendar__tile {
    padding: 24px 6.6667px;
} */

.react-calendar {
    /* width: 100%; */
    /* border-radius: 10px; */
}

.MuiStepper-horizontal {
    display: none !important;
}

.css-7oxpd8-MuiButtonBase-root-MuiButton-root.Mui-disabled {
    display: none;
}

/* body {
    background-image: url(../img/logo2.png);
    background-repeat: no-repeat;
    background-size: 30%;
    background-position: center;
} */

.css-h56hht-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    background: #004EAA !important;
}

.css-169iwlq-MuiCalendarPicker-root {
    border: 1px solid #c4c4c4 !important;
    border-radius: 10px
}